import { module as Md } from 'modujs';
import serializer from 'form-serialize';
import download from 'downloadjs';

export default class extends Md {
  constructor(m: Md) {
    super(m);

    this.form = this.$<HTMLFormElement>('form')[0] || null;
    this.button = this.$<HTMLFormElement>('button')[0] || null;
  }

  init() {
    if (this.form) {
      this.form.addEventListener('submit', (ev: Event) => {
        ev.preventDefault();
        this.button?.setAttribute('disabled', '');
        this.form?.setAttribute('data-busy', '');
        const data = serializer(this.form, { hash: true, empty: true })
        fetch('/gated', {
          method: 'POST',
          body: JSON.stringify(data)
        })
          .then(r => r.json())
          .then((r) => {
            this.button?.removeAttribute('disabled');
            this.form?.removeAttribute('data-busy');
            if (r.url) {
              download(r.url);
            }
          });
      });
    }
  }
}
